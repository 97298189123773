import React from 'react';
import {observable} from "mobx";
import Config from './../constants/Config';

class Network extends React.Component {

    isLoading = observable.box(false);
    modal = observable.box(false);
    userProfile = observable({});
    activeDoctors = observable([]);
    newDoctors = observable([]);
    history = null;

    constructor(props) {
        super(props);
        // mobx.autorun(() => {
        //
        // });
    }
}

const network = new Network();
export default network;

export function login(login, password) {
    return new Promise(function (resolve, reject) {
        fetch(Config.apiDomain + "/v1/user/login?login=" + login + '&password=' + password, {
            method: "GET",
            mode: 'cors',
            'Access-Control-Allow-Origin': 'http://localhost:80',
            'Access-Control-Max-Age': 3600,
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
        })
            .then(result => {
                return result.json();
            })
            .then(res => {
                console.log(res);
                if (res.status === 200) {
                    network.userProfile = res.profile;
                    localStorage.setItem('authToken', res.profile.auth_key);
                    resolve();
                } else {
                    reject(res.message);
                }
            })
            .catch(err => {
                console.log('error: ' + err);
                reject(err);
            });
    });
}

export function logout() {
    return new Promise(function (resolve, reject) {
        network.userProfile = null;
        localStorage.removeItem('authToken');
        resolve();
        // fetch(Config.apiDomain+"/v1/user/logout", {
        //   method: "GET",
        //   mode: 'cors',
        //   'Access-Control-Allow-Origin': 'http://localhost:80',
        //   'Access-Control-Max-Age': 3600,
        //   headers: {
        //     'Accept': 'application/json',
        //     'Content-Type': 'application/json',
        //     'Authorization': 'Bearer ' + localStorage.getItem('authToken')
        //   },
        // })
        // .then(result => {
        //   return result.json();
        // })
        // .then(res => {
        //     console.log(res);
        //     if(res.status === 200) {
        //       network.userProfile = null;
        //       localStorage.removeItem('authToken');
        //       resolve();
        //     } else {
        //       if(res.status === 401) {
        //         network.history.push('/login');
        //       } else {
        //         reject(res.message);
        //       }
        //     }
        // })
        // .catch(err => {
        //   console.log('error: '+err);
        //   reject(err);
        // });
    });
}

export function getProfile() {
    return new Promise(function (resolve, reject) {
        fetch(Config.apiDomain + "/v1/user/get-profile", {
            method: "GET",
            mode: 'cors',
            'Access-Control-Allow-Origin': 'http://localhost:80',
            'Access-Control-Max-Age': 3600,
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem('authToken')
            },
        })
            .then(result => {
                return result.json();
            })
            .then(res => {
                console.log('getProfile:::: ' + res);
                if (res.status === 200) {
                    network.userProfile = res.profile;
                    resolve();
                } else {
                    if (res.status === 401) {
                        network.history.push('/login');
                    } else {
                        reject(res.message);
                    }
                }
            })
            .catch(err => {
                console.log('error: ' + err);
                reject(err);
            });
    });
}

export function getUnits() {
    return new Promise(function (resolve, reject) {
        fetch(Config.apiDomain + "/v1/units/get-units", {
            method: "GET",
            mode: 'cors',
            'Access-Control-Allow-Origin': 'http://localhost:80',
            'Access-Control-Max-Age': 3600,
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem('authToken')
            },
        })
            .then(result => {
                return result.json();
            })
            .then(res => {
                console.log(res);
                if (res.status === 200) {
                    resolve(res.units);
                } else {
                    if (res.status === 401) {
                        network.history.push('/login');
                    } else {
                        reject(res.message);
                    }
                }
            })
            .catch(err => {
                console.log('error: ' + err);
                reject(err);
            });
    });
}

export function saveUnit(id, name) {
    return new Promise(function (resolve, reject) {
        fetch(Config.apiDomain + "/v1/units/save", {
            method: "POST",
            mode: 'cors',
            'Access-Control-Allow-Origin': 'http://localhost:80',
            'Access-Control-Max-Age': 3600,
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem('authToken')
            },
            body: JSON.stringify({
                id,
                name,
            })
        })
            .then(result => {
                return result.json();
            })
            .then(res => {
                console.log(res);
                if (res.status === 200) {
                    resolve();
                } else {
                    if (res.status === 401) {
                        network.history.push('/login');
                    } else {
                        if (res.hasOwnProperty('code')) {
                            reject('Ошибка базы данных!');
                        } else {
                            reject(res.message);
                        }
                    }
                }
            })
            .catch(err => {
                console.log('error: ' + err);
                reject(err);
            });
    });
}

export function deleteUnit(id) {
    return new Promise(function (resolve, reject) {
        fetch(Config.apiDomain + "/v1/units/delete-unit?id=" + id, {
            method: "GET",
            mode: 'cors',
            'Access-Control-Allow-Origin': 'http://localhost:80',
            'Access-Control-Max-Age': 3600,
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem('authToken')
            },
        })
            .then(result => {
                return result.json();
            })
            .then(res => {
                console.log(res);
                if (res.status === 200) {
                    resolve();
                } else {
                    if (res.status === 401) {
                        network.history.push('/login');
                    } else {
                        if (res.hasOwnProperty('code')) {
                            reject('Ошибка базы данных!');
                        } else {
                            reject(res.message);
                        }
                    }
                }
            })
            .catch(err => {
                console.log('error: ' + err);
                reject(err);
            });
    });
}

export function getRanks() {
    return new Promise(function (resolve, reject) {
        fetch(Config.apiDomain + "/v1/rank/get-ranks", {
            method: "GET",
            mode: 'cors',
            'Access-Control-Allow-Origin': 'http://localhost:80',
            'Access-Control-Max-Age': 3600,
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem('authToken')
            },
        })
            .then(result => {
                return result.json();
            })
            .then(res => {
                console.log(res);
                if (res.status === 200) {
                    resolve(res.ranks);
                } else {
                    if (res.status === 401) {
                        network.history.push('/login');
                    } else {
                        reject(res.message);
                    }
                }
            })
            .catch(err => {
                console.log('error: ' + err);
                reject(err);
            });
    });
}

export function saveRank(id, name) {
    return new Promise(function (resolve, reject) {
        fetch(Config.apiDomain + "/v1/rank/save", {
            method: "POST",
            mode: 'cors',
            'Access-Control-Allow-Origin': 'http://localhost:80',
            'Access-Control-Max-Age': 3600,
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem('authToken')
            },
            body: JSON.stringify({
                id,
                name,
            })
        })
            .then(result => {
                return result.json();
            })
            .then(res => {
                console.log(res);
                if (res.status === 200) {
                    resolve();
                } else {
                    if (res.status === 401) {
                        network.history.push('/login');
                    } else {
                        if (res.hasOwnProperty('code')) {
                            reject('Ошибка базы данных!');
                        } else {
                            reject(res.message);
                        }
                    }
                }
            })
            .catch(err => {
                console.log('error: ' + err);
                reject(err);
            });
    });
}

export function deleteRank(id) {
    return new Promise(function (resolve, reject) {
        fetch(Config.apiDomain + "/v1/rank/delete-rank?id=" + id, {
            method: "GET",
            mode: 'cors',
            'Access-Control-Allow-Origin': 'http://localhost:80',
            'Access-Control-Max-Age': 3600,
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem('authToken')
            },
        })
            .then(result => {
                return result.json();
            })
            .then(res => {
                console.log(res);
                if (res.status === 200) {
                    resolve();
                } else {
                    if (res.status === 401) {
                        network.history.push('/login');
                    } else {
                        if (res.hasOwnProperty('code')) {
                            reject('Ошибка базы данных!');
                        } else {
                            reject(res.message);
                        }
                    }
                }
            })
            .catch(err => {
                console.log('error: ' + err);
                reject(err);
            });
    });
}

export function getDigrees() {
    return new Promise(function (resolve, reject) {
        fetch(Config.apiDomain + "/v1/digree/get-digrees", {
            method: "GET",
            mode: 'cors',
            'Access-Control-Allow-Origin': 'http://localhost:80',
            'Access-Control-Max-Age': 3600,
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem('authToken')
            },
        })
            .then(result => {
                return result.json();
            })
            .then(res => {
                console.log(res);
                if (res.status === 200) {
                    resolve(res.digrees);
                } else {
                    if (res.status === 401) {
                        network.history.push('/login');
                    } else {
                        reject(res.message);
                    }
                }
            })
            .catch(err => {
                console.log('error: ' + err);
                reject(err);
            });
    });
}

export function saveDigree(id, name) {
    return new Promise(function (resolve, reject) {
        fetch(Config.apiDomain + "/v1/digree/save", {
            method: "POST",
            mode: 'cors',
            'Access-Control-Allow-Origin': 'http://localhost:80',
            'Access-Control-Max-Age': 3600,
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem('authToken')
            },
            body: JSON.stringify({
                id,
                name,
            })
        })
            .then(result => {
                return result.json();
            })
            .then(res => {
                console.log(res);
                if (res.status === 200) {
                    resolve();
                } else {
                    if (res.status === 401) {
                        network.history.push('/login');
                    } else {
                        if (res.hasOwnProperty('code')) {
                            reject('Ошибка базы данных!');
                        } else {
                            reject(res.message);
                        }
                    }
                }
            })
            .catch(err => {
                console.log('error: ' + err);
                reject(err);
            });
    });
}

export function deleteDigree(id) {
    return new Promise(function (resolve, reject) {
        fetch(Config.apiDomain + "/v1/digree/delete-digree?id=" + id, {
            method: "GET",
            mode: 'cors',
            'Access-Control-Allow-Origin': 'http://localhost:80',
            'Access-Control-Max-Age': 3600,
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem('authToken')
            },
        })
            .then(result => {
                return result.json();
            })
            .then(res => {
                console.log(res);
                if (res.status === 200) {
                    resolve();
                } else {
                    if (res.status === 401) {
                        network.history.push('/login');
                    } else {
                        if (res.hasOwnProperty('code')) {
                            reject('Ошибка базы данных!');
                        } else {
                            reject(res.message);
                        }
                    }
                }
            })
            .catch(err => {
                console.log('error: ' + err);
                reject(err);
            });
    });
}

export function setAnketa(id, value) {
    return new Promise(function (resolve, reject) {
        fetch(Config.apiDomain + "/v1/anketa/set-anketa", {
            method: "POST",
            mode: 'cors',
            'Access-Control-Allow-Origin': 'http://localhost:80',
            'Access-Control-Max-Age': 3600,
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem('authToken')
            },
            body: JSON.stringify({
                id,
                value
            })
        })
            .then(result => {
                return result.json();
            })
            .then(res => {
                console.log(res);
                if (res.status === 200) {
                    resolve();
                } else {
                    if (res.status === 401) {
                        network.history.push('/login');
                    } else {
                        if (res.hasOwnProperty('code')) {
                            reject('Ошибка базы данных!');
                        } else {
                            reject(res.message);
                        }
                    }
                }
            })
            .catch(err => {
                console.log('error: ' + err);
                reject(err);
            });
    });
}

export function getAnketas() {
    return new Promise(function (resolve, reject) {
        fetch(Config.apiDomain + "/v1/anketa/get-anketas", {
            method: "GET",
            mode: 'cors',
            'Access-Control-Allow-Origin': 'http://localhost:80',
            'Access-Control-Max-Age': 3600,
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem('authToken')
            },
        })
            .then(result => {
                return result.json();
            })
            .then(res => {
                console.log(res);
                if (res.status === 200) {
                    resolve(res.anketas);
                } else {
                    if (res.status === 401) {
                        network.history.push('/login');
                    } else {
                        reject(res.message);
                    }
                }
            })
            .catch(err => {
                console.log('error: ' + err);
                reject(err);
            });
    });
}

export function saveAnketa(id, name) {
    return new Promise(function (resolve, reject) {
        fetch(Config.apiDomain + "/v1/anketa/save-anketa", {
            method: "POST",
            mode: 'cors',
            'Access-Control-Allow-Origin': 'http://localhost:80',
            'Access-Control-Max-Age': 3600,
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem('authToken')
            },
            body: JSON.stringify({
                id,
                name,
            })
        })
            .then(result => {
                return result.json();
            })
            .then(res => {
                console.log(res);
                if (res.status === 200) {
                    resolve();
                } else {
                    if (res.status === 401) {
                        network.history.push('/login');
                    } else {
                        if (res.hasOwnProperty('code')) {
                            reject('Ошибка базы данных!');
                        } else {
                            reject(res.message);
                        }
                    }
                }
            })
            .catch(err => {
                console.log('error: ' + err);
                reject(err);
            });
    });
}

export function deleteAnketa(id) {
    return new Promise(function (resolve, reject) {
        fetch(Config.apiDomain + "/v1/anketa/delete-anketa?id=" + id, {
            method: "GET",
            mode: 'cors',
            'Access-Control-Allow-Origin': 'http://localhost:80',
            'Access-Control-Max-Age': 3600,
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem('authToken')
            },
        })
            .then(result => {
                return result.json();
            })
            .then(res => {
                console.log(res);
                if (res.status === 200) {
                    resolve();
                } else {
                    if (res.status === 401) {
                        network.history.push('/login');
                    } else {
                        if (res.hasOwnProperty('code')) {
                            reject('Ошибка базы данных!');
                        } else {
                            reject(res.message);
                        }
                    }
                }
            })
            .catch(err => {
                console.log('error: ' + err);
                reject(err);
            });
    });
}

export function getParts() {
    return new Promise(function (resolve, reject) {
        fetch(Config.apiDomain + "/v1/anketa/get-parts", {
            method: "GET",
            mode: 'cors',
            'Access-Control-Allow-Origin': 'http://localhost:80',
            'Access-Control-Max-Age': 3600,
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem('authToken')
            },
        })
            .then(result => {
                return result.json();
            })
            .then(res => {
                console.log(res);
                if (res.status === 200) {
                    resolve(res.parts);
                } else {
                    if (res.status === 401) {
                        network.history.push('/login');
                    } else {
                        reject(res.message);
                    }
                }
            })
            .catch(err => {
                console.log('error: ' + err);
                reject(err);
            });
    });
}

export function savePart(id, name, anketa_id) {
    return new Promise(function (resolve, reject) {
        fetch(Config.apiDomain + "/v1/anketa/save", {
            method: "POST",
            mode: 'cors',
            'Access-Control-Allow-Origin': 'http://localhost:80',
            'Access-Control-Max-Age': 3600,
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem('authToken')
            },
            body: JSON.stringify({
                id,
                name,
                anketa_id,
            })
        })
            .then(result => {
                return result.json();
            })
            .then(res => {
                console.log(res);
                if (res.status === 200) {
                    resolve();
                } else {
                    if (res.status === 401) {
                        network.history.push('/login');
                    } else {
                        if (res.hasOwnProperty('code')) {
                            reject('Ошибка базы данных!');
                        } else {
                            reject(res.message);
                        }
                    }
                }
            })
            .catch(err => {
                console.log('error: ' + err);
                reject(err);
            });
    });
}

export function deletePart(id) {
    return new Promise(function (resolve, reject) {
        fetch(Config.apiDomain + "/v1/anketa/delete-part?id=" + id, {
            method: "GET",
            mode: 'cors',
            'Access-Control-Allow-Origin': 'http://localhost:80',
            'Access-Control-Max-Age': 3600,
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem('authToken')
            },
        })
            .then(result => {
                return result.json();
            })
            .then(res => {
                console.log(res);
                if (res.status === 200) {
                    resolve();
                } else {
                    if (res.status === 401) {
                        network.history.push('/login');
                    } else {
                        if (res.hasOwnProperty('code')) {
                            reject('Ошибка базы данных!');
                        } else {
                            reject(res.message);
                        }
                    }
                }
            })
            .catch(err => {
                console.log('error: ' + err);
                reject(err);
            });
    });
}

export function getSections() {
    return new Promise(function (resolve, reject) {
        fetch(Config.apiDomain + "/v1/anketa/get-sections", {
            method: "GET",
            mode: 'cors',
            'Access-Control-Allow-Origin': 'http://localhost:80',
            'Access-Control-Max-Age': 3600,
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem('authToken')
            },
        })
            .then(result => {
                return result.json();
            })
            .then(res => {
                console.log(res);
                if (res.status === 200) {
                    resolve(res.sections);
                } else {
                    if (res.status === 401) {
                        network.history.push('/login');
                    } else {
                        reject(res.message);
                    }
                }
            })
            .catch(err => {
                console.log('error: ' + err);
                reject(err);
            });
    });
}

export function saveSection(id, name, part) {
    return new Promise(function (resolve, reject) {
        fetch(Config.apiDomain + "/v1/anketa/save-section", {
            method: "POST",
            mode: 'cors',
            'Access-Control-Allow-Origin': 'http://localhost:80',
            'Access-Control-Max-Age': 3600,
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem('authToken')
            },
            body: JSON.stringify({
                id,
                name,
                part,
            })
        })
            .then(result => {
                return result.json();
            })
            .then(res => {
                console.log(res);
                if (res.status === 200) {
                    resolve();
                } else {
                    if (res.status === 401) {
                        network.history.push('/login');
                    } else {
                        if (res.hasOwnProperty('code')) {
                            reject('Ошибка базы данных!');
                        } else {
                            reject(res.message);
                        }
                    }
                }
            })
            .catch(err => {
                console.log('error: ' + err);
                reject(err);
            });
    });
}

export function deleteSection(id) {
    return new Promise(function (resolve, reject) {
        fetch(Config.apiDomain + "/v1/anketa/delete-section?id=" + id, {
            method: "GET",
            mode: 'cors',
            'Access-Control-Allow-Origin': 'http://localhost:80',
            'Access-Control-Max-Age': 3600,
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem('authToken')
            },
        })
            .then(result => {
                return result.json();
            })
            .then(res => {
                console.log(res);
                if (res.status === 200) {
                    resolve();
                } else {
                    if (res.status === 401) {
                        network.history.push('/login');
                    } else {
                        if (res.hasOwnProperty('code')) {
                            reject('Ошибка базы данных!');
                        } else {
                            reject(res.message);
                        }
                    }
                }
            })
            .catch(err => {
                console.log('error: ' + err);
                reject(err);
            });
    });
}

export function getQuestions() {
    return new Promise(function (resolve, reject) {
        fetch(Config.apiDomain + "/v1/anketa/get-questions", {
            method: "GET",
            mode: 'cors',
            'Access-Control-Allow-Origin': 'http://localhost:80',
            'Access-Control-Max-Age': 3600,
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem('authToken')
            },
        })
            .then(result => {
                return result.json();
            })
            .then(res => {
                console.log(res);
                if (res.status === 200) {
                    resolve(res.questions);
                } else {
                    if (res.status === 401) {
                        network.history.push('/login');
                    } else {
                        reject(res.message);
                    }
                }
            })
            .catch(err => {
                console.log('error: ' + err);
                reject(err);
            });
    });
}

export function saveQuestion(id, name, part, section, sex, answers) {
    return new Promise(function (resolve, reject) {
        fetch(Config.apiDomain + "/v1/anketa/save-question", {
            method: "POST",
            mode: 'cors',
            'Access-Control-Allow-Origin': 'http://localhost:80',
            'Access-Control-Max-Age': 3600,
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem('authToken')
            },
            body: JSON.stringify({
                id,
                name,
                part,
                section,
                sex,
                answers,
            })
        })
            .then(result => {
                return result.json();
            })
            .then(res => {
                console.log(res);
                if (res.status === 200) {
                    resolve();
                } else {
                    if (res.status === 401) {
                        network.history.push('/login');
                    } else {
                        if (res.hasOwnProperty('code')) {
                            reject('Ошибка базы данных!');
                        } else {
                            reject(res.message);
                        }
                    }
                }
            })
            .catch(err => {
                console.log('error: ' + err);
                reject(err);
            });
    });
}

export function deleteQuestion(id) {
    return new Promise(function (resolve, reject) {
        fetch(Config.apiDomain + "/v1/anketa/delete-question?id=" + id, {
            method: "GET",
            mode: 'cors',
            'Access-Control-Allow-Origin': 'http://localhost:80',
            'Access-Control-Max-Age': 3600,
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem('authToken')
            },
        })
            .then(result => {
                return result.json();
            })
            .then(res => {
                console.log(res);
                if (res.status === 200) {
                    resolve();
                } else {
                    if (res.status === 401) {
                        network.history.push('/login');
                    } else {
                        if (res.hasOwnProperty('code')) {
                            reject('Ошибка базы данных!');
                        } else {
                            reject(res.message);
                        }
                    }
                }
            })
            .catch(err => {
                console.log('error: ' + err);
                reject(err);
            });
    });
}

export function getAnalisys() {
    return new Promise(function (resolve, reject) {
        fetch(Config.apiDomain + "/v1/analis/get-analisys", {
            method: "GET",
            mode: 'cors',
            'Access-Control-Allow-Origin': 'http://localhost:80',
            'Access-Control-Max-Age': 3600,
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem('authToken')
            },
        })
            .then(result => {
                return result.json();
            })
            .then(res => {
                console.log(res);
                if (res.status === 200) {
                    resolve(res.analisys);
                } else {
                    if (res.status === 401) {
                        network.history.push('/login');
                    } else {
                        reject(res.message);
                    }
                }
            })
            .catch(err => {
                console.log('error: ' + err);
                reject(err);
            });
    });
}

export function saveAnalis(id, name, description, unit, sex, params, min, max, group_id, digit_round) {
    return new Promise(function (resolve, reject) {
        fetch(Config.apiDomain + "/v1/analis/save", {
            method: "POST",
            mode: 'cors',
            'Access-Control-Allow-Origin': 'http://localhost:80',
            'Access-Control-Max-Age': 3600,
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem('authToken')
            },
            body: JSON.stringify({
                id,
                name,
                description,
                unit,
                sex,
                params,
                min,
                max,
                group_id,
                digit_round,
            })
        })
            .then(result => {
                return result.json();
            })
            .then(res => {
                console.log(res);
                if (res.status === 200) {
                    resolve();
                } else {
                    if (res.status === 401) {
                        network.history.push('/login');
                    } else {
                        if (res.hasOwnProperty('code')) {
                            reject('Ошибка базы данных!');
                        } else {
                            reject(res.message);
                        }
                    }
                }
            })
            .catch(err => {
                console.log('error: ' + err);
                reject(err);
            });
    });
}

export function deleteAnalis(id) {
    return new Promise(function (resolve, reject) {
        fetch(Config.apiDomain + "/v1/analis/delete-analis?id=" + id, {
            method: "GET",
            mode: 'cors',
            'Access-Control-Allow-Origin': 'http://localhost:80',
            'Access-Control-Max-Age': 3600,
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem('authToken')
            },
        })
            .then(result => {
                return result.json();
            })
            .then(res => {
                console.log(res);
                if (res.status === 200) {
                    resolve();
                } else {
                    if (res.status === 401) {
                        network.history.push('/login');
                    } else {
                        if (res.hasOwnProperty('code')) {
                            reject('Ошибка базы данных!');
                        } else {
                            reject(res.message);
                        }
                    }
                }
            })
            .catch(err => {
                console.log('error: ' + err);
                reject(err);
            });
    });
}

export function getSpecs() {
    return new Promise(function (resolve, reject) {
        fetch(Config.apiDomain + "/v1/specialities/get-specs", {
            method: "GET",
            mode: 'cors',
            'Access-Control-Allow-Origin': 'http://localhost:80',
            'Access-Control-Max-Age': 3600,
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem('authToken')
            },
        })
            .then(result => {
                return result.json();
            })
            .then(res => {
                console.log(res);
                if (res.status === 200) {
                    resolve(res.specs);
                } else {
                    if (res.status === 401) {
                        network.history.push('/login');
                    } else {
                        reject(res.message);
                    }
                }
            })
            .catch(err => {
                console.log('error: ' + err);
                reject(err);
            });
    });
}

export function saveSpec(id, name) {
    return new Promise(function (resolve, reject) {
        fetch(Config.apiDomain + "/v1/specialities/save", {
            method: "POST",
            mode: 'cors',
            'Access-Control-Allow-Origin': 'http://localhost:80',
            'Access-Control-Max-Age': 3600,
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem('authToken')
            },
            body: JSON.stringify({
                id,
                name,
            })
        })
            .then(result => {
                return result.json();
            })
            .then(res => {
                console.log(res);
                if (res.status === 200) {
                    resolve();
                } else {
                    if (res.status === 401) {
                        network.history.push('/login');
                    } else {
                        if (res.hasOwnProperty('code')) {
                            reject('Ошибка базы данных!');
                        } else {
                            reject(res.message);
                        }
                    }
                }
            })
            .catch(err => {
                console.log('error: ' + err);
                reject(err);
            });
    });
}

export function deleteSpec(id) {
    return new Promise(function (resolve, reject) {
        fetch(Config.apiDomain + "/v1/specialities/delete-spec?id=" + id, {
            method: "GET",
            mode: 'cors',
            'Access-Control-Allow-Origin': 'http://localhost:80',
            'Access-Control-Max-Age': 3600,
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem('authToken')
            },
        })
            .then(result => {
                return result.json();
            })
            .then(res => {
                console.log(res);
                if (res.status === 200) {
                    resolve();
                } else {
                    if (res.status === 401) {
                        network.history.push('/login');
                    } else {
                        if (res.hasOwnProperty('code')) {
                            reject('Ошибка базы данных!');
                        } else {
                            reject(res.message);
                        }
                    }
                }
            })
            .catch(err => {
                console.log('error: ' + err);
                reject(err);
            });
    });
}

export function getPermissions() {
    return new Promise(function (resolve, reject) {
        fetch(Config.apiDomain + "/v1/settings/get-permissions", {
            method: "GET",
            mode: 'cors',
            'Access-Control-Allow-Origin': 'http://localhost:80',
            'Access-Control-Max-Age': 3600,
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem('authToken')
            },
        })
            .then(result => {
                return result.json();
            })
            .then(res => {
                console.log(res);
                if (res.status === 200) {
                    resolve(res.permissions);
                } else {
                    if (res.status === 401) {
                        network.history.push('/login');
                    } else {
                        reject(res.message);
                    }
                }
            })
            .catch(err => {
                console.log('error: ' + err);
                reject(err);
            });
    });
}

export function updatePermission(role, permission, value) {
    return new Promise(function (resolve, reject) {
        fetch(Config.apiDomain + "/v1/settings/update-permission", {
            method: "POST",
            mode: 'cors',
            'Access-Control-Allow-Origin': 'http://localhost:80',
            'Access-Control-Max-Age': 3600,
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem('authToken')
            },
            body: JSON.stringify({
                role,
                permission,
                value
            })
        })
            .then(result => {
                return result.json();
            })
            .then(res => {
                console.log(res);
                if (res.status === 200) {
                    resolve();
                } else {
                    if (res.status === 401) {
                        network.history.push('/login');
                    } else {
                        if (res.hasOwnProperty('code')) {
                            reject('Ошибка базы данных!');
                        } else {
                            reject(res.message);
                        }
                    }
                }
            })
            .catch(err => {
                console.log('error: ' + err);
                reject(err);
            });
    });
}

export function getMonitors() {
    return new Promise(function (resolve, reject) {
        fetch(Config.apiDomain + "/v1/monitor/get-monitors", {
            method: "GET",
            mode: 'cors',
            'Access-Control-Allow-Origin': 'http://localhost:80',
            'Access-Control-Max-Age': 3600,
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem('authToken')
            },
        })
            .then(result => {
                return result.json();
            })
            .then(res => {
                console.log(res);
                if (res.status === 200) {
                    resolve(res.monitors);
                } else {
                    if (res.status === 401) {
                        network.history.push('/login');
                    } else {
                        reject(res.message);
                    }
                }
            })
            .catch(err => {
                console.log('error: ' + err);
                reject(err);
            });
    });
}

export function saveMonitor(id, name, description, norm1, norm2, icon, graph_type, enter_type, is_default, unit_id, ios, android, polar, garmin, isTask, min, max, digit_round) {
    return new Promise(function (resolve, reject) {
        fetch(Config.apiDomain + "/v1/monitor/save", {
            method: "POST",
            mode: 'cors',
            'Access-Control-Allow-Origin': 'http://localhost:80',
            'Access-Control-Max-Age': 3600,
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem('authToken')
            },
            body: JSON.stringify({
                id,
                name,
                description,
                norm1,
                norm2,
                icon,
                graph_type,
                enter_type,
                isTask,
                is_default,
                unit_id,
                ios,
                android,
                polar,
                garmin,
                min,
                max,
                digit_round,
            })
        })
            .then(result => {
                return result.json();
            })
            .then(res => {
                console.log(res);
                if (res.status === 200) {
                    resolve();
                } else {
                    if (res.status === 401) {
                        network.history.push('/login');
                    } else {
                        if (res.hasOwnProperty('code')) {
                            reject('Ошибка базы данных!');
                        } else {
                            reject(res.message);
                        }
                    }
                }
            })
            .catch(err => {
                console.log('error: ' + err);
                reject(err);
            });
    });
}

export function deleteMonitor(id) {
    return new Promise(function (resolve, reject) {
        fetch(Config.apiDomain + "/v1/monitor/delete-monitor?id=" + id, {
            method: "GET",
            mode: 'cors',
            'Access-Control-Allow-Origin': 'http://localhost:80',
            'Access-Control-Max-Age': 3600,
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem('authToken')
            },
        })
            .then(result => {
                return result.json();
            })
            .then(res => {
                console.log(res);
                if (res.status === 200) {
                    resolve();
                } else {
                    if (res.status === 401) {
                        network.history.push('/login');
                    } else {
                        if (res.hasOwnProperty('code')) {
                            reject('Ошибка базы данных!');
                        } else {
                            reject(res.message);
                        }
                    }
                }
            })
            .catch(err => {
                console.log('error: ' + err);
                reject(err);
            });
    });
}


export function getUsers() {
    return new Promise(function (resolve, reject) {
        fetch(Config.apiDomain + "/v1/user/get-users", {
            method: "GET",
            mode: 'cors',
            'Access-Control-Allow-Origin': 'http://localhost:80',
            'Access-Control-Max-Age': 3600,
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem('authToken')
            },
        })
            .then(result => {
                return result.json();
            })
            .then(res => {
                console.log(res);
                if (res.status === 200) {
                    resolve(res.users);
                } else {
                    if (res.status === 401) {
                        network.history.push('/login');
                    } else {
                        reject(res.message);
                    }
                }
            })
            .catch(err => {
                console.log('error: ' + err);
                reject(err);
            });
    });
}


export function getUser(userId) {
    return new Promise(function (resolve, reject) {
        fetch(Config.apiDomain + "/v1/user/get-user?userId=" + userId, {
            method: "GET",
            mode: 'cors',
            'Access-Control-Allow-Origin': 'http://localhost:80',
            'Access-Control-Max-Age': 3600,
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem('authToken')
            },
        })
            .then(result => {
                return result.json();
            })
            .then(res => {
                if (res.status === 200) {
                    resolve(res.user);
                } else {
                    if (res.status === 401) {
                        network.history.push('/login');
                    } else {
                        reject(res.message);
                    }
                }
            })
            .catch(err => {
                console.log('error: ' + err);
                reject(err);
            });
    });
}

export function getUserResearch(userId) {
    return new Promise(function (resolve, reject) {
        fetch(Config.apiDomain + "/v1/tasks/get-research-history-by-doctor?pacient_id=" + userId, {
            method: "GET",
            mode: 'cors',
            'Access-Control-Allow-Origin': 'http://localhost:80',
            'Access-Control-Max-Age': 3600,
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem('authToken')
            },
        })
            .then(result => {
                return result.json();
            })
            .then(res => {
                if (res.status === 200) {
                    resolve(res);
                } else {
                    if (res.status === 401) {
                        network.history.push('/login');
                    } else {
                        reject(res.message);
                    }
                }
            })
            .catch(err => {
                console.log('error: ' + err);
                reject(err);
            });
    });
}

export function deleteResearchPhoto(research_id, photo_id) {
    return new Promise(function (resolve, reject) {
        let path = '/v1/tasks/delete-research-photo?research_id=' + research_id + '&photo_id=' + photo_id;
        fetch(Config.apiDomain + path, {
            method: "GET",
            mode: 'cors',
            'Access-Control-Allow-Origin': 'http://localhost:80',
            'Access-Control-Max-Age': 3600,
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem('authToken')
            },
        })
            .then(result => {
                return result.json();
            })
            .then(res => {
                if (res.status === 200) {
                    resolve(res);
                } else {
                    if (res.status === 401) {
                        network.history.push('/login');
                    } else {
                        reject(res.message);
                    }
                }
            })
            .catch(err => {
                console.log('error: ' + err);
                reject(err);
            });
    });
}



export function updateUser(id, fio, email, role, subjects, environment_id, is_super_patient) {
    return new Promise(function (resolve, reject) {
        fetch(Config.apiDomain + "/v1/user/update-user", {
            method: "POST",
            mode: 'cors',
            'Access-Control-Allow-Origin': 'http://localhost:80',
            'Access-Control-Max-Age': 3600,
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem('authToken')
            },
            body: JSON.stringify({
                id,
                fio,
                email,
                role,
                subjects,
                environment_id,
                is_super_patient,
            })
        })
            .then(result => {
                return result.json();
            })
            .then(res => {
                console.log(res);
                if (res.status === 200) {
                    resolve();
                } else {
                    if (res.status === 401) {
                        network.history.push('/login');
                    } else {
                        if (res.hasOwnProperty('code')) {
                            reject('Ошибка базы данных!');
                        } else {
                            reject(res.message);
                        }
                    }
                }
            })
            .catch(err => {
                console.log('error: ' + err);
                reject(err);
            });
    });
}

export function newPassword(userId) {
    return new Promise(function (resolve, reject) {
        fetch(Config.apiDomain + "/v1/user/new-password?id=" + userId, {
            method: "GET",
            mode: 'cors',
            'Access-Control-Allow-Origin': 'http://localhost:80',
            'Access-Control-Max-Age': 3600,
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem('authToken')
            },
        })
            .then(result => {
                return result.json();
            })
            .then(res => {
                console.log(res);
                if (res.status === 200) {
                    resolve();
                } else {
                    if (res.status === 401) {
                        network.history.push('/login');
                    } else {
                        if (res.hasOwnProperty('code')) {
                            reject('Ошибка базы данных!');
                        } else {
                            reject(res.message);
                        }
                    }
                }
            })
            .catch(err => {
                console.log('error: ' + err);
                reject(err);
            });
    });
}

export function setUserStatus(userId, status) {
    return new Promise(function (resolve, reject) {
        fetch(Config.apiDomain + "/v1/user/set-user-status?id=" + userId + '&status=' + status, {
            method: "GET",
            mode: 'cors',
            'Access-Control-Allow-Origin': 'http://localhost:80',
            'Access-Control-Max-Age': 3600,
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem('authToken')
            },
        })
            .then(result => {
                return result.json();
            })
            .then(res => {
                console.log(res);
                if (res.status === 200) {
                    resolve();
                } else {
                    if (res.status === 401) {
                        network.history.push('/login');
                    } else {
                        if (res.hasOwnProperty('code')) {
                            reject('Ошибка базы данных!');
                        } else {
                            reject(res.message);
                        }
                    }
                }
            })
            .catch(err => {
                console.log('error: ' + err);
                reject(err);
            });
    });
}

export function saveProfile(fio, about, education, service_enabled, service_price, avatar) {

    return new Promise(function (resolve, reject) {

        fetch(Config.apiDomain + '/v1/user/update-profile', {
            method: 'post',
            headers: {
                'Authorization': 'Bearer ' + localStorage.getItem('authToken'),
                // 'Content-Type': 'multipart/form-data',
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                fio,
                avatar,
                about,
                education,
                service_price,
                service_enabled,
            })
        }).then(response => {
            response.json().then(data => {
                console.warn('saveProfile: ' + JSON.stringify(data));
                if (data.status == 200) {
                    let user = data.profile;
                    network.userProfile = user;
                    resolve();
                } else {
                    reject(data.message);
                }
            });
        }).catch(err => {
            console.warn(err)
            reject('Неизвестная ошибка. Повторите снова.');
        });
    });
}

export function getLessons(id) {
    return new Promise(function (resolve, reject) {
        fetch(Config.apiDomain + "/v1/subjects/get-lessons?id=" + id, {
            method: "GET",
            mode: 'cors',
            'Access-Control-Allow-Origin': 'http://localhost:80',
            'Access-Control-Max-Age': 3600,
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem('authToken')
            },
        })
            .then(result => {
                return result.json();
            })
            .then(res => {
                console.log(res);
                if (res.status === 200) {
                    resolve(res.lessons);
                } else {
                    if (res.status === 401) {
                        network.history.push('/login');
                    } else {
                        if (res.hasOwnProperty('code')) {
                            reject('Ошибка базы данных!');
                        } else {
                            reject(res.message);
                        }
                    }
                }
            })
            .catch(err => {
                console.log('error: ' + err);
                reject(err);
            });
    });
}

export function saveLesson(id, subject_id, name, description, body, image, paid, show) {
    return new Promise(function (resolve, reject) {
        fetch(Config.apiDomain + "/v1/subjects/update-lesson", {
            method: "POST",
            mode: 'cors',
            'Access-Control-Allow-Origin': 'http://localhost:80',
            'Access-Control-Max-Age': 3600,
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem('authToken')
            },
            body: JSON.stringify({
                id,
                subject_id,
                name,
                description,
                body,
                image,
                paid,
                show,
            })
        })
            .then(result => {
                return result.json();
            })
            .then(res => {
                console.log(res);
                if (res.status === 200) {
                    resolve();
                } else {
                    if (res.status === 401) {
                        network.history.push('/login');
                    } else {
                        if (res.hasOwnProperty('code')) {
                            reject('Ошибка базы данных!');
                        } else {
                            reject(res.message);
                        }
                    }
                }
            })
            .catch(err => {
                console.log('error: ' + err);
                reject(err);
            });
    });
}

export function deleteLesson(id) {
    return new Promise(function (resolve, reject) {
        fetch(Config.apiDomain + "/v1/subjects/delete-lesson?id=" + id, {
            method: "GET",
            mode: 'cors',
            'Access-Control-Allow-Origin': 'http://localhost:80',
            'Access-Control-Max-Age': 3600,
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem('authToken')
            },
        })
            .then(result => {
                return result.json();
            })
            .then(res => {
                console.log(res);
                if (res.status === 200) {
                    resolve();
                } else {
                    if (res.status === 401) {
                        network.history.push('/login');
                    } else {
                        if (res.hasOwnProperty('code')) {
                            reject('Ошибка базы данных!');
                        } else {
                            reject(res.message);
                        }
                    }
                }
            })
            .catch(err => {
                console.log('error: ' + err);
                reject(err);
            });
    });
}

export function getBalls() {
    return new Promise(function (resolve, reject) {
        fetch(Config.apiDomain + "/v1/anketa-balls/get-balls", {
            method: "GET",
            mode: 'cors',
            'Access-Control-Allow-Origin': 'http://localhost:80',
            'Access-Control-Max-Age': 3600,
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem('authToken')
            },
        })
            .then(result => {
                return result.json();
            })
            .then(res => {
                console.log(res);
                if (res.status === 200) {
                    resolve(res.balls);
                } else {
                    if (res.status === 401) {
                        network.history.push('/login');
                    } else {
                        if (res.hasOwnProperty('code')) {
                            reject('Ошибка базы данных!');
                        } else {
                            reject(res.message);
                        }
                    }
                }
            })
            .catch(err => {
                console.log('error: ' + err);
                reject(err);
            });
    });
}

export function saveAnketaBalls(id, part_id, section_id, score1_man, score2_man, score1_woman, score2_woman) {
    return new Promise(function (resolve, reject) {
        fetch(Config.apiDomain + "/v1/anketa-balls/save", {
            method: "POST",
            mode: 'cors',
            'Access-Control-Allow-Origin': 'http://localhost:80',
            'Access-Control-Max-Age': 3600,
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem('authToken')
            },
            body: JSON.stringify({
                id,
                part_id,
                section_id,
                score1_man,
                score1_woman,
                score2_man,
                score2_woman,
            })
        })
            .then(result => {
                return result.json();
            })
            .then(res => {
                console.log(res);
                if (res.status === 200) {
                    resolve();
                } else {
                    if (res.status === 401) {
                        network.history.push('/login');
                    } else {
                        if (res.hasOwnProperty('code')) {
                            reject('Ошибка базы данных!');
                        } else {
                            reject(res.message);
                        }
                    }
                }
            })
            .catch(err => {
                console.log('error: ' + err);
                reject(err);
            });
    });
}

export function deleteBall(id) {
    return new Promise(function (resolve, reject) {
        fetch(Config.apiDomain + "/v1/anketa-balls/delete-ball?id=" + id, {
            method: "GET",
            mode: 'cors',
            'Access-Control-Allow-Origin': 'http://localhost:80',
            'Access-Control-Max-Age': 3600,
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem('authToken')
            },
        })
            .then(result => {
                return result.json();
            })
            .then(res => {
                console.log(res);
                if (res.status === 200) {
                    resolve();
                } else {
                    if (res.status === 401) {
                        network.history.push('/login');
                    } else {
                        if (res.hasOwnProperty('code')) {
                            reject('Ошибка базы данных!');
                        } else {
                            reject(res.message);
                        }
                    }
                }
            })
            .catch(err => {
                console.log('error: ' + err);
                reject(err);
            });
    });
}

export function getDiets() {
    return new Promise(function (resolve, reject) {
        fetch(Config.apiDomain + "/v1/diets/get-diets", {
            method: "GET",
            mode: 'cors',
            'Access-Control-Allow-Origin': 'http://localhost:80',
            'Access-Control-Max-Age': 3600,
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem('authToken')
            },
        })
            .then(result => {
                return result.json();
            })
            .then(res => {
                console.log(res);
                if (res.status === 200) {
                    resolve(res.diets);
                } else {
                    if (res.status === 401) {
                        network.history.push('/login');
                    } else {
                        if (res.hasOwnProperty('code')) {
                            reject('Ошибка базы данных!');
                        } else {
                            reject(res.message);
                        }
                    }
                }
            })
            .catch(err => {
                console.log('error: ' + err);
                reject(err);
            });
    });
}

export function saveDiet(id, name, description, allowed_products, denied_products) {
    return new Promise(function (resolve, reject) {
        fetch(Config.apiDomain + "/v1/diets/save", {
            method: "POST",
            mode: 'cors',
            'Access-Control-Allow-Origin': 'http://localhost:80',
            'Access-Control-Max-Age': 3600,
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem('authToken')
            },
            body: JSON.stringify({
                id,
                name,
                description,
                allowed_products,
                denied_products,
            })
        })
            .then(result => {
                return result.json();
            })
            .then(res => {
                console.log(res);
                if (res.status === 200) {
                    resolve();
                } else {
                    if (res.status === 401) {
                        network.history.push('/login');
                    } else {
                        if (res.hasOwnProperty('code')) {
                            reject('Ошибка базы данных!');
                        } else {
                            reject(res.message);
                        }
                    }
                }
            })
            .catch(err => {
                console.log('error: ' + err);
                reject(err);
            });
    });
}

export function deleteDiet(id) {
    return new Promise(function (resolve, reject) {
        fetch(Config.apiDomain + "/v1/diets/delete-diet?id=" + id, {
            method: "GET",
            mode: 'cors',
            'Access-Control-Allow-Origin': 'http://localhost:80',
            'Access-Control-Max-Age': 3600,
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem('authToken')
            },
        })
            .then(result => {
                return result.json();
            })
            .then(res => {
                console.log(res);
                if (res.status === 200) {
                    resolve();
                } else {
                    if (res.status === 401) {
                        network.history.push('/login');
                    } else {
                        if (res.hasOwnProperty('code')) {
                            reject('Ошибка базы данных!');
                        } else {
                            reject(res.message);
                        }
                    }
                }
            })
            .catch(err => {
                console.log('error: ' + err);
                reject(err);
            });
    });
}

export function getTasks() {
    return new Promise(function (resolve, reject) {
        fetch(Config.apiDomain + "/v1/tasks/get-tasks", {
            method: "GET",
            mode: 'cors',
            'Access-Control-Allow-Origin': 'http://localhost:80',
            'Access-Control-Max-Age': 3600,
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem('authToken')
            },
        })
            .then(result => {
                return result.json();
            })
            .then(res => {
                console.log(res);
                if (res.status === 200) {
                    resolve(res.tasks);
                } else {
                    if (res.status === 401) {
                        network.history.push('/login');
                    } else {
                        reject(res.message);
                    }
                }
            })
            .catch(err => {
                console.log('error: ' + err);
                reject(err);
            });
    });
}

export function saveTask(id, name, type, title, subtitle, description, icon) {
    return new Promise(function (resolve, reject) {
        fetch(Config.apiDomain + "/v1/tasks/save", {
            method: "POST",
            mode: 'cors',
            'Access-Control-Allow-Origin': 'http://localhost:80',
            'Access-Control-Max-Age': 3600,
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem('authToken')
            },
            body: JSON.stringify({
                id,
                name,
                type,
                title,
                subtitle,
                description,
                icon,
            })
        })
            .then(result => {
                return result.json();
            })
            .then(res => {
                console.log(res);
                if (res.status === 200) {
                    resolve();
                } else {
                    if (res.status === 401) {
                        network.history.push('/login');
                    } else {
                        if (res.hasOwnProperty('code')) {
                            reject('Ошибка базы данных!');
                        } else {
                            reject(res.message);
                        }
                    }
                }
            })
            .catch(err => {
                console.log('error: ' + err);
                reject(err);
            });
    });
}

export function deleteTask(id) {
    return new Promise(function (resolve, reject) {
        fetch(Config.apiDomain + "/v1/tasks/delete-task?id=" + id, {
            method: "GET",
            mode: 'cors',
            'Access-Control-Allow-Origin': 'http://localhost:80',
            'Access-Control-Max-Age': 3600,
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem('authToken')
            },
        })
            .then(result => {
                return result.json();
            })
            .then(res => {
                console.log(res);
                if (res.status === 200) {
                    resolve();
                } else {
                    if (res.status === 401) {
                        network.history.push('/login');
                    } else {
                        if (res.hasOwnProperty('code')) {
                            reject('Ошибка базы данных!');
                        } else {
                            reject(res.message);
                        }
                    }
                }
            })
            .catch(err => {
                console.log('error: ' + err);
                reject(err);
            });
    });
}

export function getDoctors() {
    return new Promise(function (resolve, reject) {
        fetch(Config.apiDomain + "/v1/user/get-doctors", {
            method: "GET",
            mode: 'cors',
            'Access-Control-Allow-Origin': 'http://localhost:80',
            'Access-Control-Max-Age': 3600,
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem('authToken')
            },
        })
            .then(result => {
                return result.json();
            })
            .then(res => {
                console.log(res);
                if (res.status === 200) {
                    // network.activeDoctors = res.active;
                    // network.newDoctors = res.new;
                    resolve(res);
                } else {
                    if (res.status === 401) {
                        network.history.push('/login');
                    } else {
                        if (res.hasOwnProperty('code')) {
                            reject('Ошибка базы данных!');
                        } else {
                            reject(res.message);
                        }
                    }
                }
            })
            .catch(err => {
                console.log('error: ' + err);
                reject(err);
            });
    });
}

export function saveDoctor(id, status, education, dop_education, special_text, isSuper, environment_id) {
    return new Promise(function (resolve, reject) {
        fetch(Config.apiDomain + "/v1/user/save-doctor-by-admin", {
            method: "POST",
            mode: 'cors',
            'Access-Control-Allow-Origin': 'http://localhost:80',
            'Access-Control-Max-Age': 3600,
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem('authToken')
            },
            body: JSON.stringify({
                id,
                status,
                education,
                dop_education,
                special_text,
                isSuper,
                environment_id,
            })
        })
            .then(result => {
                return result.json();
            })
            .then(res => {
                console.log(res);
                if (res.status === 200) {
                    resolve();
                } else {
                    if (res.status === 401) {
                        network.history.push('/login');
                    } else {
                        if (res.hasOwnProperty('code')) {
                            reject('Ошибка базы данных!');
                        } else {
                            reject(res.message);
                        }
                    }
                }
            })
            .catch(err => {
                console.log('error: ' + err);
                reject(err);
            });
    });
}

export function getAnalisysGroup() {
    return new Promise(function (resolve, reject) {
        fetch(Config.apiDomain + "/v1/analisys-group/get-analisys-group", {
            method: "GET",
            mode: 'cors',
            'Access-Control-Allow-Origin': 'http://localhost:80',
            'Access-Control-Max-Age': 3600,
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem('authToken')
            },
        })
            .then(result => {
                return result.json();
            })
            .then(res => {
                console.log(res);
                if (res.status === 200) {
                    resolve(res.groups);
                } else {
                    if (res.status === 401) {
                        network.history.push('/login');
                    } else {
                        reject(res.message);
                    }
                }
            })
            .catch(err => {
                console.log('error: ' + err);
                reject(err);
            });
    });
}

export function saveAnalisGroup(id, name) {
    return new Promise(function (resolve, reject) {
        fetch(Config.apiDomain + "/v1/analisys-group/save", {
            method: "POST",
            mode: 'cors',
            'Access-Control-Allow-Origin': 'http://localhost:80',
            'Access-Control-Max-Age': 3600,
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem('authToken')
            },
            body: JSON.stringify({
                id,
                name,
            })
        })
            .then(result => {
                return result.json();
            })
            .then(res => {
                console.log(res);
                if (res.status === 200) {
                    resolve();
                } else {
                    if (res.status === 401) {
                        network.history.push('/login');
                    } else {
                        if (res.hasOwnProperty('code')) {
                            reject('Ошибка базы данных!');
                        } else {
                            reject(res.message);
                        }
                    }
                }
            })
            .catch(err => {
                console.log('error: ' + err);
                reject(err);
            });
    });
}

export function deleteAnalisysGroup(id) {
    return new Promise(function (resolve, reject) {
        fetch(Config.apiDomain + "/v1/analisys-group/delete-analisys-group?id=" + id, {
            method: "GET",
            mode: 'cors',
            'Access-Control-Allow-Origin': 'http://localhost:80',
            'Access-Control-Max-Age': 3600,
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem('authToken')
            },
        })
            .then(result => {
                return result.json();
            })
            .then(res => {
                console.log(res);
                if (res.status === 200) {
                    resolve();
                } else {
                    if (res.status === 401) {
                        network.history.push('/login');
                    } else {
                        if (res.hasOwnProperty('code')) {
                            reject('Ошибка базы данных!');
                        } else {
                            reject(res.message);
                        }
                    }
                }
            })
            .catch(err => {
                console.log('error: ' + err);
                reject(err);
            });
    });
}

export function setAnalisysGroupOrder(orders) {
    return new Promise(function (resolve, reject) {
        fetch(Config.apiDomain + "/v1/analisys-group/set-analis-order", {
            method: "POST",
            mode: 'cors',
            'Access-Control-Allow-Origin': 'http://localhost:80',
            'Access-Control-Max-Age': 3600,
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem('authToken')
            },
            body: JSON.stringify({
                orders,
            }),
        })
            .then(result => {
                return result.json();
            })
            .then(res => {
                console.log(res);
                if (res.status === 200) {
                    resolve();
                } else {
                    if (res.status === 401) {
                        network.history.push('/login');
                    } else {
                        if (res.hasOwnProperty('code')) {
                            reject('Ошибка базы данных!');
                        } else {
                            reject(res.message);
                        }
                    }
                }
            })
            .catch(err => {
                console.log('error: ' + err);
                reject(err);
            });
    });
}

export function getAnalisysFromGroup(group_id) {
    return new Promise(function (resolve, reject) {
        fetch(Config.apiDomain + "/v1/analisys-group/get-analisys-from-group?group_id=" + group_id, {
            method: "GET",
            mode: 'cors',
            'Access-Control-Allow-Origin': 'http://localhost:80',
            'Access-Control-Max-Age': 3600,
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem('authToken')
            },
        })
            .then(result => {
                return result.json();
            })
            .then(res => {
                console.log(res);
                if (res.status === 200) {
                    resolve(res.analisys);
                } else {
                    if (res.status === 401) {
                        network.history.push('/login');
                    } else {
                        reject(res.message);
                    }
                }
            })
            .catch(err => {
                console.log('error: ' + err);
                reject(err);
            });
    });
}

export function setAnalisysOrder(orders) {
    return new Promise(function (resolve, reject) {
        fetch(Config.apiDomain + "/v1/analis/set-analis-order", {
            method: "POST",
            mode: 'cors',
            'Access-Control-Allow-Origin': 'http://localhost:80',
            'Access-Control-Max-Age': 3600,
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem('authToken')
            },
            body: JSON.stringify({
                orders,
            }),
        })
            .then(result => {
                return result.json();
            })
            .then(res => {
                console.log(res);
                if (res.status === 200) {
                    resolve();
                } else {
                    if (res.status === 401) {
                        network.history.push('/login');
                    } else {
                        if (res.hasOwnProperty('code')) {
                            reject('Ошибка базы данных!');
                        } else {
                            reject(res.message);
                        }
                    }
                }
            })
            .catch(err => {
                console.log('error: ' + err);
                reject(err);
            });
    });
}

export function getPromocodes() {
    return new Promise(function (resolve, reject) {
        fetch(Config.apiDomain + "/v1/transactions/get-promocodes", {
            method: "GET",
            mode: 'cors',
            'Access-Control-Allow-Origin': 'http://localhost:80',
            'Access-Control-Max-Age': 3600,
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem('authToken')
            },
        })
            .then(result => {
                return result.json();
            })
            .then(res => {
                console.log(res);
                if (res.status === 200) {
                    resolve(res);
                } else {
                    if (res.status === 401) {
                        network.history.push('/login');
                    } else {
                        if (res.hasOwnProperty('code')) {
                            reject('Ошибка базы данных!');
                        } else {
                            reject(res.message);
                        }
                    }
                }
            })
            .catch(err => {
                console.log('error: ' + err);
                reject(err);
            });
    });
}

export function generatePromocode() {
    return new Promise(function (resolve, reject) {
        fetch(Config.apiDomain + "/v1/transactions/generate-promocode", {
            method: "GET",
            mode: 'cors',
            'Access-Control-Allow-Origin': 'http://localhost:80',
            'Access-Control-Max-Age': 3600,
            headers: {
                'Authorization': 'Bearer ' + localStorage.getItem('authToken')
            },
        })
            .then(result => {
                return result.json();
            })
            .then(res => {
                console.log(res);
                if (res.status === 200) {
                    resolve();
                } else {
                    if (res.status === 401) {
                        network.history.push('/login');
                    } else {
                        if (res.hasOwnProperty('code')) {
                            reject('Ошибка базы данных!');
                        } else {
                            reject(res.message);
                        }
                    }
                }
            })
            .catch(err => {
                console.log('error: ' + err);
                reject(err);
            });
    });
}

export function uploadAnalyzes(formData) {
    return new Promise(function (resolve, reject) {
        fetch(Config.apiDomain + "/v1/import/analyzes", {
            method: "POST",
            mode: 'cors',
            'Access-Control-Allow-Origin': 'http://localhost:80',
            'Access-Control-Max-Age': 3600,
            headers: {
                'Authorization': 'Bearer ' + localStorage.getItem('authToken')
            },
            body: formData,
        })
            .then(result => {
                return result.json();
            })
            .then(res => {
                if (typeof res.status !== 'undefined' && res.status === 401) {
                    network.history.push('/login');
                }

                if (res.error) {
                    reject(res.error);
                }
                else {
                    resolve(res);
                }
            })
            .catch(err => {
                console.log('error: ' + err);
                reject(err);
            });
    });
}

export function uploadResearch(formData) {
    return new Promise(function (resolve, reject) {
        fetch(Config.apiDomain + "/v1/import/research", {
            method: "POST",
            mode: 'cors',
            'Access-Control-Allow-Origin': 'http://localhost:80',
            'Access-Control-Max-Age': 3600,
            headers: {
                'Authorization': 'Bearer ' + localStorage.getItem('authToken')
            },
            body: formData,
        })
            .then(result => {
                return result.json();
            })
            .then(res => {
                if (typeof res.status !== 'undefined' && res.status === 401) {
                    network.history.push('/login');
                }
                if (res.error) {
                    reject(res.error);
                }
                else {
                    resolve(res);
                }
            })
            .catch(err => {
                console.log('error: ' + err);
                reject(err);
            });
    });
}

export function importAnalyzes(fileName) {
    return new Promise(function (resolve, reject) {
        fetch(Config.apiDomain + "/v1/import/analyzes-import", {
            method: "POST",
            mode: 'cors',
            'Access-Control-Allow-Origin': 'http://localhost:80',
            'Access-Control-Max-Age': 3600,
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem('authToken')
            },
            body: JSON.stringify({
                file: fileName,
            }),
        })
            .then(result => {
                return result.json();
            })
            .then(res => {
                if (res.error) {
                    reject(res.error);
                }
                else {
                    resolve(res);
                }
            })
            .catch(err => {
                console.log('error: ' + err);
                reject(err);
            });
    });
}

export function getEnvironments() {
    return new Promise(function (resolve, reject) {
        fetch(Config.apiDomain + "/v1/environments/get-list", {
            method: "GET",
            mode: 'cors',
            'Access-Control-Allow-Origin': 'http://localhost:80',
            'Access-Control-Max-Age': 3600,
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem('authToken')
            },
        })
            .then(result => {
                return result.json();
            })
            .then(res => {
                if (res.status === 200) {
                    resolve(res.environments);
                } else {
                    if (res.status === 401) {
                        network.history.push('/login');
                    } else {
                        reject(res.message);
                    }
                }
            })
            .catch(err => {
                console.log('error: ' + err);
                reject(err);
            });
    });
}

export function addEnvironment(data) {
    return new Promise(function (resolve, reject) {
        fetch(Config.apiDomain + "/v1/environments/add", {
            method: "POST",
            mode: 'cors',
            'Access-Control-Allow-Origin': 'http://localhost:80',
            'Access-Control-Max-Age': 3600,
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem('authToken')
            },
            body: JSON.stringify(data)
        })
            .then(result => {
                return result.json();
            })
            .then(res => {
                if (res.status === 200) {
                    if (res.success) {
                        resolve(res.environments);
                    } else {
                        reject(res.errors)
                    }
                } else {
                    if (res.status === 401) {
                        network.history.push('/login');
                    } else {
                        if (res.hasOwnProperty('code')) {
                            reject('Ошибка базы данных!');
                        } else {
                            reject(res.message);
                        }
                    }
                }
            })
            .catch(err => {
                console.log('error: ' + err);
                reject(err);
            });
    });
}

export function editEnvironment(id, data) {
    data.id = id;

    return new Promise(function (resolve, reject) {
        fetch(Config.apiDomain + "/v1/environments/edit", {
            method: "POST",
            mode: 'cors',
            'Access-Control-Allow-Origin': 'http://localhost:80',
            'Access-Control-Max-Age': 3600,
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem('authToken')
            },
            body: JSON.stringify(data)
        })
            .then(result => {
                return result.json();
            })
            .then(res => {
                if (res.status === 200) {
                    if (res.success) {
                        resolve(res.environments);
                    } else {
                        reject(res.errors)
                    }
                } else {
                    if (res.status === 401) {
                        network.history.push('/login');
                    } else {
                        if (res.hasOwnProperty('code')) {
                            reject('Ошибка базы данных!');
                        } else {
                            reject(res.message);
                        }
                    }
                }
            })
            .catch(err => {
                console.log('error: ' + err);
                reject(err);
            });
    });
}

export function deleteEnvironment(id) {
    return new Promise(function (resolve, reject) {
        fetch(Config.apiDomain + "/v1/environments/delete-env?id=" + id, {
            method: "GET",
            mode: 'cors',
            'Access-Control-Allow-Origin': 'http://localhost:80',
            'Access-Control-Max-Age': 3600,
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem('authToken')
            },
        })
            .then(result => {
                return result.json();
            })
            .then(res => {
                if (res.status === 200) {
                    if (res.success) {
                        resolve(res.environments);
                    } else {
                        reject(res.errors)
                    }
                } else {
                    if (res.status === 401) {
                        network.history.push('/login');
                    } else {
                        if (res.hasOwnProperty('code')) {
                            reject('Ошибка базы данных!');
                        } else {
                            reject(res.message);
                        }
                    }
                }
            })
            .catch(err => {
                console.log('error: ' + err);
                reject(err);
            });
    });
}